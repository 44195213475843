export function getLocalStorageSize () {
    var _lsTotal = 0, _xLen, _x; for (_x in localStorage) { if (!localStorage.hasOwnProperty(_x)) { continue; } _xLen = ((localStorage[_x].length + _x.length) * 2); _lsTotal += _xLen; }; return (_lsTotal / (1024 * 1024));
}

export function getLocalStorageItemNames() {
    var values: string[] = [];
    var obj = { ...localStorage };
    for (const property in obj) {
        values.push(property);
    }
    return values;
}

export function clearElement(id: string) {
    document.getElementById(id).value = '';
}